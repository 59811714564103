import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, PageHeader, Divider, Table } from 'antd';
import { map } from 'lodash';
import { connect } from 'react-redux';
import {
  fetchLoadTestHistoryAction,
  updateLoadTestAction,
  runLoadTestAction,
  stopLoadTestAction,
} from './actions';
import style from './index.module.scss';

const LoadTestingView = ({
  match,
  history,
  fetchLoadTestHistory,
  loadTests,
  loadTestHistoryLoading,
  loadTestHistory,
  runLoadTest,
  stopLoadTest,
}) => {
  const loadTestData = loadTests.find(
    test => test.id === Number(match.params.id)
  );
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (loadTestData?.id) {
      fetchLoadTestHistory({ id: loadTestData.id });
    }
  }, [fetchLoadTestHistory, loadTestData?.id]);

  if (!loadTestData?.id) {
    history.push('/load-testing');
    return null;
  }

  return (
    <PageHeader title="Load Testing" subTitle="View and run load tests">
      <Divider />
      <div className={style['load-testing-view']}>
        <Button onClick={() => history.push('/load-testing')}> Go Back</Button>
        <div className={style['load-testing-view-heading']}>
          <div>
            <h3>Test: {loadTestData.slug}</h3>
            <div>{loadTestData.description}</div>
          </div>
          <Button
            type="primary"
            loading={loadTestData.status === 'running'}
            onClick={() => runLoadTest({ id: loadTestData.id })}
          >
            Start
          </Button>
          <Button
            type="primary"
            disabled={isRefreshing}
            onClick={() => {
              setIsRefreshing(true);
              setTimeout(() => {
                fetchLoadTestHistory({ id: loadTestData.id });
                setTimeout(() => {
                  setIsRefreshing(false);
                }, 5000);
              }, 500);
            }}
          >
            Refresh
          </Button>
        </div>
        <Table
          loading={loadTestHistoryLoading}
          dataSource={loadTestHistory}
          rowKey="id"
          columns={[
            {
              title: 'Started By',
              dataIndex: 'startedBy',
              key: 'startedBy',
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Started At',
              dataIndex: 'startedAt',
              key: 'startedAt',
              render: text => (
                <span>
                  {new Date(text).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </span>
              ),
            },
            {
              title: 'Completed At',
              dataIndex: 'completedAt',
              key: 'completedAt',
              render: text => (
                <span>
                  {text
                    ? new Date(text).toLocaleString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : ''}
                </span>
              ),
            },
            {
              title: 'Output',
              dataIndex: 'output',
              key: 'output',
              render: text => (
                <div>
                  {typeof text == 'object' && Object.keys(text) ? (
                    map(Object.keys(text), key => (
                      <div key={key}>
                        <strong>{key}</strong>: {text[key]}
                      </div>
                    ))
                  ) : (
                    <span>{text}</span>
                  )}
                </div>
              ),
            },
            {
              title: 'Action',
              key: 'action',
              render: (text, record) => (
                <Button
                  type="danger"
                  disabled={record.status !== 'PENDING'}
                  onClick={() =>
                    stopLoadTest({
                      id: record.id,
                      loadTestingId: loadTestData.id,
                    })
                  }
                >
                  Stop
                </Button>
              ),
            },
          ]}
        />
      </div>
    </PageHeader>
  );
};

const mapStateToProps = ({ loadTestingReducer }) => {
  const { loadTests, loadTestHistoryLoading, loadTestHistory } =
    loadTestingReducer;
  return {
    loadTests,
    loadTestHistoryLoading,
    loadTestHistory,
  };
};

const mapDispatchToProps = {
  fetchLoadTestHistory: fetchLoadTestHistoryAction,
  updateLoadTest: updateLoadTestAction,
  runLoadTest: runLoadTestAction,
  stopLoadTest: stopLoadTestAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadTestingView)
);
