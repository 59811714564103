import { takeLatest, put, call, select } from 'redux-saga/effects';
import { some } from 'lodash';
import {
  setLoadTestsLoadingAction,
  setLoadTestsAction,
  FETCH_LOAD_TESTS,
  UPDATE_LOAD_TEST,
  setLoadTestHistoryLoadingAction,
  setLoadTestHistoryAction,
  FETCH_LOAD_TEST_HISTORY,
  RUN_LOAD_TEST_ACTION,
  STOP_LOAD_TEST_ACTION,
  loadTestsSelector,
  loadTestHistorySelector,
} from '../actions';
import { POPUP_ALERT } from '../../../actions/alert';
import {
  getLoadTests,
  updateLoadTest,
  getLoadTestHistory,
  runLoadTest,
  stopLoadTest,
} from '../../../api/loadTestingApi';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  takeLatest(FETCH_LOAD_TESTS, fetchLoadTestsSaga),
  takeLatest(UPDATE_LOAD_TEST, updateLoadTestSaga),
  takeLatest(FETCH_LOAD_TEST_HISTORY, fetchLoadTestHistorySaga),
  takeLatest(RUN_LOAD_TEST_ACTION, runLoadTestSaga),
  takeLatest(STOP_LOAD_TEST_ACTION, stopLoadTestSaga),
];

function* fetchLoadTestsSaga() {
  try {
    yield put(setLoadTestsLoadingAction(true));
    const { data } = yield call(getLoadTests);
    yield put(setLoadTestsAction(data));
    yield put(setLoadTestsLoadingAction(false));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error', e);
    yield put(setLoadTestsLoadingAction(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching load tests' },
    });
  }
}

function* updateLoadTestSaga({ data }) {
  try {
    yield put(setLoadTestsLoadingAction(true));
    const { data: test } = yield call(updateLoadTest, data);
    const tests = yield select(loadTestsSelector);
    const testsIndex = tests.findIndex(d => d.id === test.id);
    tests[testsIndex] = test;
    yield put(setLoadTestsAction(tests));
    yield put(setLoadTestsLoadingAction(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'success', message: 'Updated load test successfully' },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error', e);
    yield put(setLoadTestsLoadingAction(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error updating load test' },
    });
  }
}

function* fetchLoadTestHistorySaga({ data }) {
  try {
    yield put(setLoadTestHistoryLoadingAction(true));
    const tests = yield select(loadTestsSelector);
    const testsIndex = tests.findIndex(d => d.id === data?.id);
    const { data: history } = yield call(getLoadTestHistory, { id: data.id });

    if (
      !some(history, h => h.status === 'PENDING') &&
      tests[testsIndex].status
    ) {
      tests[testsIndex].status = null;
      yield put(setLoadTestsAction(tests));
    }

    yield put(setLoadTestHistoryAction(history));
    yield put(setLoadTestHistoryLoadingAction(false));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error', e);
    yield put(setLoadTestHistoryLoadingAction(false));
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error fetching load test history' },
    });
  }
}

function* runLoadTestSaga({ data }) {
  const tests = yield select(loadTestsSelector);
  const testsIndex = tests.findIndex(d => d.id === data?.id);
  try {
    yield put(setLoadTestHistoryLoadingAction(true));
    tests[testsIndex].status = 'running';
    yield put(setLoadTestsAction(tests));

    const { data: history } = yield call(runLoadTest, { id: data.id });

    const testHistory = yield select(loadTestHistorySelector);
    yield put(setLoadTestHistoryAction([history, ...testHistory]));
    yield put(setLoadTestHistoryLoadingAction(false));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error', e);
    yield put(setLoadTestHistoryLoadingAction(false));
    if (testsIndex !== -1) {
      tests[testsIndex].status = null;
      yield put(setLoadTestsAction(tests));
    }
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error running load test' },
    });
  }
}

function* stopLoadTestSaga({ data }) {
  const tests = yield select(loadTestsSelector);
  const testsIndex = tests.findIndex(d => d.id === data.loadTestingId);
  try {
    yield put(setLoadTestHistoryLoadingAction(true));

    const { data: history } = yield call(stopLoadTest, { id: data.id });

    tests[testsIndex].status = null;
    yield put(setLoadTestsAction(tests));
    const testHistory = yield select(loadTestHistorySelector);
    const findHistoryIndex = testHistory.findIndex(th => th.id === history.id);
    testHistory[findHistoryIndex] = history;
    yield put(setLoadTestHistoryAction(testHistory));
    yield put(setLoadTestHistoryLoadingAction(false));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error', e);
    yield put(setLoadTestHistoryLoadingAction(false));
    if (testsIndex !== -1) {
      tests[testsIndex].status = null;
      yield put(setLoadTestsAction(tests));
    }
    yield put({
      type: POPUP_ALERT,
      data: { type: 'error', message: 'Error stopping load test' },
    });
  }
}
