const prefix = 'LoadTesting/';
export const FETCH_LOAD_TESTS = `${prefix}FETCH_LOAD_TESTS`;
export const SET_LOAD_TESTS = `${prefix}SET_LOAD_TESTS`;
export const SET_LOAD_TESTS_LOADING = `${prefix}SET_LOAD_TESTS_LOADING`;
export const UPDATE_LOAD_TEST = `${prefix}UPDATE_LOAD_TEST`;
export const FETCH_LOAD_TEST_HISTORY = `${prefix}FETCH_LOAD_TEST_HISTORY`;
export const SET_LOAD_TEST_HISTORY = `${prefix}SET_LOAD_TEST_HISTORY`;
export const SET_LOAD_TEST_HISTORY_LOADING = `${prefix}SET_LOAD_TEST_HISTORY_LOADING`;
export const RUN_LOAD_TEST_ACTION = `${prefix}RUN_LOAD_TEST_ACTION`;
export const STOP_LOAD_TEST_ACTION = `${prefix}STOP_LOAD_TEST_ACTION`;

export const fetchLoadTestsAction = () => ({
  type: FETCH_LOAD_TESTS,
});

export const setLoadTestsAction = data => ({
  type: SET_LOAD_TESTS,
  data,
});

export const setLoadTestsLoadingAction = data => ({
  type: SET_LOAD_TESTS_LOADING,
  data,
});

export const updateLoadTestAction = data => ({
  type: UPDATE_LOAD_TEST,
  data,
});

export const fetchLoadTestHistoryAction = data => ({
  type: FETCH_LOAD_TEST_HISTORY,
  data,
});

export const setLoadTestHistoryAction = data => ({
  type: SET_LOAD_TEST_HISTORY,
  data,
});

export const setLoadTestHistoryLoadingAction = data => ({
  type: SET_LOAD_TEST_HISTORY_LOADING,
  data,
});

export const runLoadTestAction = data => ({
  type: RUN_LOAD_TEST_ACTION,
  data,
});

export const stopLoadTestAction = data => ({
  type: STOP_LOAD_TEST_ACTION,
  data,
});

// selectors
export const loadTestsSelector = ({ loadTestingReducer }) =>
  loadTestingReducer.loadTests;
export const loadTestHistorySelector = ({ loadTestingReducer }) =>
  loadTestingReducer.loadTestHistory;
