import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import styles from './index.module.scss';

const EditableText = ({ value, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (text !== value) {
      onChange(text);
    }
  };

  const handleChange = e => {
    setText(e.target.value);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  return isEditing ? (
    <Input
      ref={inputRef}
      value={text}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      className={styles.input}
    />
  ) : (
    <span
      onClick={handleClick}
      onKeyDown={e => e.key === 'Enter' && handleClick()}
      className={styles.text}
      role="button"
      tabIndex={0}
    >
      {value}
    </span>
  );
};

EditableText.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditableText;
