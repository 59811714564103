import { request } from '../utils/request';

export const getLoadTests = () => request.get('/load-testing');
export const updateLoadTest = data =>
  request.put(`/load-testing/${data.id}`, data);

export const getLoadTestHistory = data =>
  request.get(`/load-testing/${data.id}/history`);

export const runLoadTest = data => request.get(`/load-testing/${data.id}/run`);
export const stopLoadTest = data =>
  request.get(`/load-testing/history/${data.id}/stop`);
