import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  PageHeader,
  Popconfirm,
  Switch,
  Row,
  Col,
  Select,
  Tag,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { debounce, isEmpty, isNull } from 'lodash';
import Search from 'antd/lib/input/Search';
import { connect } from 'react-redux';

import { fetchOccupationsAction, updateOccupationsAction } from '../actions';

const ManageOccupationsContainer = ({
  occupations,
  totalOccupations,
  fetchOccupations,
  updateOccupations,
  isLoading,
  activeCareerPaths,
}) => {
  const history = useHistory();
  const [searchTerm, setSerchTerm] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: totalOccupations,
  });
  const [editParentCareerPath, setEditParentCareerPath] = useState(null);

  const getOccupationColumns = () => [
    {
      title: 'O*NET ID',
      dataIndex: 'onet_id',
      key: 'onet_id',
      width: 150,
      render: value => <Tag>{value}</Tag>,
    },
    {
      title: 'Occupation Title',
      dataIndex: 'title',
      key: 'title',
      render: (value, record) => (
        <div>
          <a
            href={`https://www.mynextmove.org/profile/summary/${record.onet_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
        </div>
      ),
    },
    {
      title: 'isActive',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (value, record) => (
        <Switch
          defaultChecked={!!record.is_active}
          checked={value}
          loading={record.id === isLoading.updateOccupations}
          disabled={!record.career_path_id}
          onChange={isActive =>
            updateOccupations({
              isActive,
              occupationId: record.id,
            })
          }
        />
      ),
    },
    {
      title: 'Parent Career Path',
      dataIndex: 'career_path_id',
      key: 'career_path_id',
      width: 350,
      render: (value, record) => {
        const careerPathTitle = activeCareerPaths.find(
          ({ id }) => id === value
        )?.title;
        return (
          <div>
            {editParentCareerPath === record.id ? (
              <Select
                showSearch
                value={isNull(value) ? undefined : value} // to fix antd placeholder
                placeholder="Select a Career Path to link"
                onChange={async e => {
                  await updateOccupations({
                    careerPathId: e,
                    occupationId: record.id,
                  });
                  setEditParentCareerPath(null);
                }}
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onBlur={() => setEditParentCareerPath(null)}
              >
                {activeCareerPaths.map(({ id, title }) => (
                  <Select.Option key={id} value={id}>
                    {title}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <>
                {careerPathTitle ? (
                  <div className="career-path-cell">
                    <p>{careerPathTitle}</p>
                    <Button.Group>
                      <Button
                        type="default"
                        size="small"
                        onClick={() => setEditParentCareerPath(record.id)}
                      >
                        Edit
                      </Button>
                      <Popconfirm
                        title="Are you sure you want to unlink this occupation?"
                        onConfirm={() =>
                          updateOccupations({
                            careerPathId: null,
                            occupationId: record.id,
                          })
                        }
                        okText="Unlink"
                        cancelText="Cancel"
                        placement="topLeft"
                      >
                        <Button size="small" type="danger">
                          Unlink
                        </Button>
                      </Popconfirm>
                    </Button.Group>
                  </div>
                ) : (
                  <div className="career-path-cell">
                    <p>N/A</p>
                    <Button
                      type="dashed"
                      size="small"
                      icon="link"
                      onClick={() => setEditParentCareerPath(record.id)}
                    >
                      Link
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const getOccupations = () => {
    fetchOccupations({
      current: pagination.current,
      pageSize: pagination.pageSize,
      title: searchTerm.trim(),
      fetchCareerPaths: isEmpty(activeCareerPaths),
    });
  };

  // fetch on search term
  useEffect(() => {
    const debouncedFunc = debounce(() => {
      getOccupations();
    }, 500);

    debouncedFunc();

    return () => {
      debouncedFunc.cancel();
    };
  }, [searchTerm, pagination]);

  return (
    <div className="manage-occupations-container">
      <PageHeader title="Manage Career Titles">
        <Row>
          <Col
            span={24}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              type="primary"
              icon="caret-left"
              onClick={() => history.goBack()}
            >
              Back to Manage Career Paths
            </Button>
            <Search
              placeholder="Search By Occupation Title"
              style={{ width: 400 }}
              value={searchTerm}
              onChange={e => {
                setPagination(prev => ({
                  ...prev,
                  current: 1,
                }));
                setSerchTerm(e.target.value);
              }}
              onSearch={getOccupations}
              allowClear
            />
            <div />
          </Col>
        </Row>
      </PageHeader>
      <Table
        loading={isLoading.occupations}
        columns={getOccupationColumns()}
        dataSource={occupations}
        pagination={{
          total: Number(totalOccupations),
          pageSize: pagination.pageSize,
          current: pagination.current,
          showSizeChanger: true,
          onShowSizeChange: (current, pageSize) =>
            setPagination(prev => ({ ...prev, current, pageSize })),
          onChange: (current, pageSize) =>
            setPagination(prev => ({ ...prev, current, pageSize })),
        }}
      />
    </div>
  );
};

const mapStateToProps = ({
  manageCareerPaths: {
    occupations,
    totalOccupations,
    isLoading = {},
    activeCareerPaths = [],
  },
}) => ({
  occupations,
  totalOccupations,
  isLoading,
  activeCareerPaths,
});

const mapDispatchToProps = {
  fetchOccupations: fetchOccupationsAction,
  updateOccupations: updateOccupationsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageOccupationsContainer);
