import { combineReducers } from 'redux';
import auth from './auth';
import alert from './alert';
import admin from './admin';
import configureBanner from './configureBanner';
import clusterHubs from './clusterHubs';
import productUpdates from './productUpdates';
import userDetail from '../containers/UserDetail/reducers';
import seeds from '../containers/DatabaseImages/reducers';
import abTesting from './abTestingReducer';
import adminTag from './adminTagsReducer';
import cluster from '../containers/ClusterManagement/reducers';
import hub from '../containers/HubManagement/reducers';
import features from '../containers/FeatureManagement/reducers';
import esLogstash from '../containers/EsLogstash/reducers';
import schools from '../containers/SchoolManagement/reducers';
import feedback from '../containers/Feedback/reducers';
import cache from '../containers/ManageCache/reducers';
import fileUpload from '../containers/FileUpload/reducers';
import analyticsQuery from './analyticsQuery';
import analyticsReport from '../containers/AnalyticsReport/reducers';
import esDocument from '../containers/RefreshES/reducers';
import automatedMarketing from '../containers/AutomatedMarketing/reducers';
import bulkEmail from '../containers/ManageBulkEmail/reducers';
import manageImport from '../containers/ManageImport/reducers';
import PathwaysProvisioning from '../containers/PathwaysProvisioning/reducers';
import bridgesAlliance from '../containers/BridgesAlliance/reducers';
import groupsProgramsTier from '../containers/GroupProgramTier/reducers';
import groupsManage from '../containers/GroupProgramTier/reducers/manageReducer';
import accessManagement from '../containers/AccessManagement/reducer';
import session from '../containers/SessionManagement/reducers';
import usertypeManage from '../containers/UserTypeSettings/reducers/manageReducer';
import analyticsProfile from '../containers/AnalyticsProfile/reducers/analyticsProfile';
import standardHubManagement from '../containers/StandardHubManagement/reducers';
import requestedHubDeletion from '../containers/ApproveDeletedHubs/reducers';
import processAlertService from '../containers/ProcessAlertService/reducers';
import manageCareerPaths from '../containers/ManageCareerPaths/reducers';
import CompanyIndustry from '../containers/CompanyIndustry/reducers';
import cmsTool from '../containers/CMSTool/reducer';
import templatesAndTips from '../containers/TemplatesAndTips/reducer';
import MLService from '../containers/MLService/reducers';
import compassReducer from '../containers/Compass/reducer';
import loadTestingReducer from '../containers/LoadTesting/reducers';
import socSyncReducer from '../containers/SocSync/reducer';

const rootReducer = combineReducers({
  auth,
  alert,
  admin,
  userDetail,
  seeds,
  abTesting,
  adminTag,
  cluster,
  hub,
  features,
  configureBanner,
  productUpdates,
  clusterHubs,
  esLogstash,
  schools,
  feedback,
  cache,
  fileUpload,
  analyticsQuery,
  analyticsReport,
  esDocument,
  automatedMarketing,
  bulkEmail,
  manageImport,
  PathwaysProvisioning,
  bridgesAlliance,
  groupsProgramsTier,
  groupsManage,
  accessManagement,
  session,
  usertypeManage,
  analyticsProfile,
  standardHubManagement,
  requestedHubDeletion,
  processAlertService,
  manageCareerPaths,
  CompanyIndustry,
  cmsTool,
  templatesAndTips,
  MLService,
  compassReducer,
  loadTestingReducer,
  socSyncReducer,
});

export default rootReducer;
