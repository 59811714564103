export const FETCH_ALL_HUBS = 'SocSync/FETCH_ALL_HUBS';
export const SET_SOC_SYNC_DATA = 'SocSync/SET_SOC_SYNC_DATA';
export const RUN_SOC_SYNC_DATA = 'SocSync/RUN_SOC_SYNC_DATA';
export const RUN_SOC_FAILED_SYNC_DATA = 'SocSync/RUN_SOC_LOG_SYNC_DATA';

export const fetchAllHubs = data => ({ type: FETCH_ALL_HUBS, data });
export const runSyncSocNow = data => ({ type: RUN_SOC_SYNC_DATA, data });
export const runFailedLogSyncAction = data => ({
  type: RUN_SOC_FAILED_SYNC_DATA,
  data,
});
