import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ManageCareerPathsContainer from './containers/ManageCareerPathsContainer';
import ManageOccupationsContainer from './containers/ManageOccupationsContainer';
import ManageSubjectAreasContainer from './containers/ManageSubjectAreasContainer';
import ManageProgramsContainer from './containers/ManageProgramsContainer';

const ManageCareerPaths = () => (
  <Switch>
    <Route
      path="/manage-career-paths/occupations"
      component={ManageOccupationsContainer}
    />
    <Route
      path="/manage-career-paths/manage-subject-areas"
      component={ManageSubjectAreasContainer}
    />
    <Route
      path="/manage-career-paths/programs"
      component={ManageProgramsContainer}
    />
    <Route path="/" component={ManageCareerPathsContainer} />
  </Switch>
);

export default ManageCareerPaths;
