import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageHeader, Row, Col, Select, Spin, Icon, Button, Tabs } from 'antd';

import {
  fetchAllHubs,
  runSyncSocNow,
  runFailedLogSyncAction,
} from './actions';

const { TabPane } = Tabs;

class SocSyncContainer extends React.Component {

  state = {
    selectedHub: null,
  }

  componentDidMount() {
    const { fetchAllHubs } = this.props;
    fetchAllHubs();
  }

  getHubOptions = () => {
    const { allAvailableHubs } = this.props;
    return allAvailableHubs.map(hub => <Option value={hub.id} key={hub.id}>{hub.id} - {hub.name}</Option>);
  }

  runSync = (hubId) => {
    console.log('renderRunButton')
    const { runSyncSocNow } = this.props;
    runSyncSocNow({ hubId });
  }

  runFailedLogSync = (hubId) => {
    console.log('renderFailedLogsRunButton')
    const { runFailedLogSyncAction } = this.props;
    runFailedLogSyncAction({ hubId });
  }

  renderRunButton = () => {
    const { selectedHub } = this.state;
    const { runningSyncLoader } = this.props;
    return (
      <Button disabled={!selectedHub} loading={runningSyncLoader} type="primary" onClick={() => this.runSync(selectedHub)}>
        Run Sync
      </Button>
    );
  };

  renderFailedLogsRunButton = () => {
    const { selectedHub } = this.state;
    const { runningFailedSyncLoader } = this.props;
    return (
      <Button disabled={!selectedHub} loading={runningFailedSyncLoader} type="primary" onClick={() => this.runFailedLogSync(selectedHub)}>
        Run Sync
      </Button>
    );
  };

  handleHubChange = (hubId) => {
    if (!hubId) return;

    this.setState({ selectedHub: hubId })
  }

  handleTabChange = () => {
    this.setState({ selectedHub: null })
  }

  render() {
    const { hubsLoading } = this.props;
    const { selectedHub } = this.state;

    if (hubsLoading) {
      return (
        <div className={['es-logstgash-lodr']}>
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    return (
      <div>
        <PageHeader title="Soc Sync" />
        <Row className="container" gutter={{ lg: 25 }}>
          <Col span={24}>
            <Tabs defaultActiveKey="1" onChange={this.handleTabChange}>
              <TabPane tab="New Soc Sync" key="1">
                <Row>
                  <div>
                    <h3>Run a new sync process:</h3>
                    <Row gutter={16}>
                      <Col span={6}>
                        <Select
                          mode="default"
                          style={{ width: '100%' }}
                          placeholder="Select Hubs"
                          onChange={this.handleHubChange}
                          value={selectedHub}
                        >
                          {this.getHubOptions()}
                        </Select>
                      </Col>
                      <Col span={4}>
                        {this.renderRunButton()}
                      </Col>
                    </Row>
                  </div>
                </Row>
              </TabPane>
              <TabPane tab="Failed Logs" key="2">
                <h3>Run failed logs:</h3>
                <Row gutter={16}>
                  <Col span={6}>
                    <Select
                      mode="default"
                      style={{ width: '100%' }}
                      placeholder="Select Hubs"
                      onChange={this.handleHubChange}
                      value={selectedHub}
                    >
                      {this.getHubOptions()}
                    </Select>
                  </Col>
                  <Col span={4}>
                    {this.renderFailedLogsRunButton()}
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ socSyncReducer: { allAvailableHubs, hubsLoading, runningSyncLoader, runningFailedSyncLoader } }) => ({ allAvailableHubs, hubsLoading, runningSyncLoader, runningFailedSyncLoader });

const mapDispatchToProps = {
  fetchAllHubs,
  runSyncSocNow,
  runFailedLogSyncAction
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SocSyncContainer)
);
