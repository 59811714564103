import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  PageHeader,
  Row,
  Col,
  Modal,
  Input,
  Form,
  Popconfirm,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import {
  fetchSubjectAreas,
  updateSubjectAreas,
  createSubjectArea,
  deleteSubjectArea,
} from '../actions';
import '../index.scss';

const { TextArea } = Input;

const ManageSubjectAreasContainer = ({ subjectAreas, isLoading }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    id: '',
    name: '',
    description: '',
    isEditing: false,
  });

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchSubjectAreas());
  }, [dispatch]);

  const handleChange = (key, value) => {
    setValues(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const getSubjectAreaColumns = () => [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            size="small"
            onClick={() =>
              setValues(prev => ({
                isEditing: true,
                id: record.id,
                name: record.name,
                description: record.description,
              }))
            }
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this subject area?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const resetForm = () => {
    setIsCreateModalVisible(false);
    setValues({
      id: '',
      name: '',
      description: '',
      isEditing: false,
    });
  };

  const handleSave = () => {
    dispatch(updateSubjectAreas(values));
    resetForm();
  };

  const handleDelete = id => {
    dispatch(deleteSubjectArea(id));
  };

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCreateSubmit = () => {
    const { name, description } = values;
    dispatch(
      createSubjectArea({
        name,
        description,
      })
    );
    setIsCreateModalVisible(false);
  };

  return (
    <div className="manage-subject-areas-container">
      <PageHeader title="Manage Subject Areas">
        <Row>
          <Col
            span={24}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              type="primary"
              icon="caret-left"
              onClick={() => history.goBack()}
            >
              Back to Manage Career Paths
            </Button>
            <Button type="primary" onClick={showCreateModal}>
              Create Subject Area
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <Table
        loading={isLoading.subjectAreas}
        columns={getSubjectAreaColumns()}
        dataSource={subjectAreas}
        rowKey="id"
      />
      {/* Edit Modal */}
      <Modal
        title={values.isEditing ? 'Edit Subject Area' : 'Create Subject Area'}
        visible={values.isEditing || isCreateModalVisible}
        onCancel={resetForm}
        footer={null}
        destroyOnClose
      >
        {(values.isEditing || isCreateModalVisible) && (
          <>
            <Form>
              <Form.Item label="Name">
                <Input
                  name="name"
                  value={values.name}
                  onChange={e => handleChange('name', e.target.value)}
                  placeholder="Enter name here"
                />
              </Form.Item>
              <Form.Item label="Description">
                <TextArea
                  name="description"
                  value={values.description}
                  onChange={e => handleChange('description', e.target.value)}
                  placeholder="Enter description here"
                  rows={4}
                />
              </Form.Item>
              <div className="manage-subject-areas-button-group">
                <Button onClick={resetForm}>Cancel</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading?.updateSubjectArea}
                  onClick={
                    isCreateModalVisible ? handleCreateSubmit : handleSave
                  }
                >
                  Save
                </Button>
              </div>
            </Form>
          </>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = ({
  manageCareerPaths: { subjectAreas, isLoading },
}) => ({
  subjectAreas,
  isLoading,
});

export default connect(mapStateToProps)(ManageSubjectAreasContainer);
