import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  PageHeader,
  Popconfirm,
  Switch,
  Row,
  Col,
  Icon,
  Dropdown,
  Menu,
  Tooltip,
} from 'antd';
import { isEmpty, isObject } from 'lodash';
import Search from 'antd/lib/input/Search';
import { useHistory } from 'react-router-dom';
import uuid from '../../../utils/uuid';

import {
  fetchCareerPaths as fetchCareerPathsAction,
  fetchCareerPathsMeta as fetchCareerPathsMetaAction,
  fetchCareerPathWithAssociatedData as fetchCareerPathWithAssociatedDataAction,
  addCareerPath as addCareerPathAction,
  updateCareerPath as updateCareerPathAction,
  fetchAuxiliaryDataForCareerPaths as fetchAuxiliaryDataForCareerPathsAction,
  setCareerPathWithAssociatedData as setCareerPathWithAssociatedDataAction,
  fetchCareerPathChangeLog as fetchCareerPathChangeLogAction,
  revertCareerPathChange as revertCareerPathChangeAction,
  deleteCareerPath as deleteCareerPathAction,
  toggleCareerPathActiveStatus as toggleCareerPathActiveStatusAction,
  searchTextChange as searchTextChangeAction,
  clearFilters as clearFiltersAction,
  setSortBy as setSortByAction,
  setAddingKey as setAddingKeyAction,
  setEditingKey as setEditingKeyAction,
  setChangeLogKey as setChangeLogKeyAction,
} from '../actions';
import EditModal from '../components/editModal';
import ChangeLogModal from '../components/changeLogModal';
import {
  columnsToShowAtListingPage,
  getEmptyDataForCareerPathKey,
} from '../utils';
import '../index.scss';
import LinkedProjectsModal from '../components/LinkedProjects/LinkedProjectsModal';

const ManageCareerPathsContainer = ({
  fetchCareerPaths,
  fetchCareerPathsMeta,
  fetchCareerPathWithAssociatedData,
  fetchAuxiliaryDataForCareerPaths,
  fetchCareerPathChangeLog,
  revertCareerPathChange,
  deleteCareerPath,
  careerPathsMeta,
  toggleCareerPathActiveStatus,
  careerPaths,
  isLoading,
  addCareerPath,
  updateCareerPath,
  careerPathWithAssociatedData,
  setCareerPathWithAssociatedData,
  auxiliaryDataForCareerPaths,
  careerPathChangeLog,
  searchText,
  searchTextChange,
  clearFilters,
  filtersEnabled,
  setSortBy,
  sortBy,
  numPages,
  addingKey,
  editingKey,
  changeLogKey,
  setAddingKey,
  setEditingKey,
  setChangeLogKey,
}) => {
  const history = useHistory();
  useEffect(() => {
    fetchCareerPathsMeta();
    fetchCareerPaths({ page: 1 });
    fetchAuxiliaryDataForCareerPaths();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [antdTableColumns, setAntdTableColumns] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [linkedProjectsKey, setLinkedProjectsKey] = useState(-1);

  const add = () => {
    const tempID = uuid();
    const columnDetails = Object.entries(
      careerPathsMeta?.careerPathsColumns || {}
    );
    const emptyCareerPath = columnDetails.reduce(
      (pV, [columnTitle, columnDetail]) => ({
        ...pV,
        ...(columnTitle === 'id'
          ? { id: tempID, key: tempID }
          : { [columnTitle]: getEmptyDataForCareerPathKey(columnDetail) }),
      }),
      {}
    );

    emptyCareerPath.skillsAbilities = [];
    emptyCareerPath.twitterAccounts = [];
    emptyCareerPath.tags = [];
    emptyCareerPath.alternativeTitles = [];
    emptyCareerPath.standardDataRelationships = {};
    emptyCareerPath.dayInLifeVideos = [];

    setAddingKey(tempID);
    setCareerPathWithAssociatedData(emptyCareerPath);
  };

  const edit = careerPath => {
    const { id: careerPathId } = careerPath;
    setEditingKey(careerPathId);
  };

  const cancel = () => {
    setEditingKey(-1);
    setAddingKey(-1);
    setChangeLogKey(-1);
  };

  const save = values => {
    if (addingKey !== -1) {
      addCareerPath({ careerPathData: values });
    } else {
      const oldCareerPathValues = { ...careerPathWithAssociatedData };
      delete oldCareerPathValues.skillsAbilities;
      updateCareerPath({
        careerPathId: editingKey,
        oldCareerPath: oldCareerPathValues,
        newCareerPath: values,
      });
    }
  };

  const closeChangeLogModal = () => {
    setChangeLogKey(-1);
  };

  const onClickPreview = record => {
    let baseUrl;
    if (process.env.REACT_APP_REGION === 'DEV_US')
      baseUrl =
        'https://clusterv2corporate.dev.pg-test.com/hub/newhub410717568350';
    else if (process.env.REACT_APP_REGION === 'STAGING_US')
      baseUrl =
        'https://surveytest5.staging.pg-test.com/hub/newhub929770407351';
    else if (process.env.REACT_APP_REGION === 'PROD_US')
      baseUrl =
        'https://stceciliaconnect.peoplegrove.com/hub/st-cecilia-connect';
    else baseUrl = '';
    baseUrl &&
      window.open(`${baseUrl}/career-paths/career/${record.slug}`, '_blank');
  };

  useEffect(() => {
    // calculate the columns
    if (!isEmpty(careerPathsMeta)) {
      const { careerPathsColumns } = careerPathsMeta;
      const columnTitles = Object.keys(careerPathsColumns);
      const tableColumns = [];
      columnTitles.forEach(col => {
        if (columnsToShowAtListingPage.includes(col)) {
          tableColumns.push({
            title: col,
            dataIndex: col,
            key: col,
            render: (value, record) => {
              if (col === 'isActive') {
                return (
                  <Switch
                    defaultChecked={!!record.is_active}
                    loading={
                      record.id === isLoading.toggleCareerPathActiveStatus
                    }
                    onChange={val =>
                      toggleCareerPathActiveStatus({
                        isActive: val,
                        careerPathId: record.id,
                      })
                    }
                  />
                );
              }
              if (isObject(value)) return JSON.stringify(value);
              return `${value}`;
            },
            width: col === 'title' ? 500 : 150,
          });
        }
      });

      tableColumns.push({
        title: 'changelog',
        dataIndex: 'changelog',
        key: 'changelog',
        render: (text, record) => (
          <Button
            size="small"
            type="link"
            onClick={() => {
              fetchCareerPathChangeLog({ careerPathId: record.id });
              setChangeLogKey(record.id);
            }}
          >
            view changelog
          </Button>
        ),
      });

      tableColumns.push({
        title: 'DIYProjects',
        dataIndex: 'DIYProjects',
        key: 'DIYProjects',
        render: (text, record) => (
          <Button
            size="small"
            type="link"
            onClick={() => {
              setLinkedProjectsKey(record.id);
            }}
          >
            Manage Linked Projects
          </Button>
        ),
      });

      tableColumns.push({
        title: 'actions',
        dataIndex: 'actions',
        fixed: 'right',
        render: (text, record) => (
          <Button.Group>
            <Button
              size="small"
              icon="eye"
              onClick={() => onClickPreview(record)}
            >
              Preview
            </Button>
            <Button
              size="small"
              disabled={editingKey !== -1}
              onClick={() => edit(record)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to delete this careerPath?"
              onConfirm={() => deleteCareerPath({ careerPathId: record.id })}
              okText="Yes"
              cancelText="No"
              placement="topLeft"
            >
              <Button size="small" type="danger">
                Delete
              </Button>
            </Popconfirm>
          </Button.Group>
        ),
      });
      setAntdTableColumns(tableColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerPathsMeta, editingKey]);

  useEffect(() => {
    if (editingKey !== -1 || changeLogKey !== -1) {
      fetchCareerPathWithAssociatedData({
        careerPathId: editingKey !== -1 ? editingKey : changeLogKey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingKey, changeLogKey]);

  const handleChangeInSearch = e => {
    searchTextChange(e.target.value);
  };

  const clearAllFilters = () => {
    clearFilters();
    fetchCareerPaths({ page: currPage });
  };

  const handleSearch = e => {
    setSearchTerm(e);
    fetchCareerPaths({ page: 1, title: e });
  };

  const handleSortChange = e => {
    const { key } = e;
    setSortBy(key);
    fetchCareerPaths({ page: currPage, orderBy: key });
  };

  const handlePagination = (page, pageSize) => {
    setCurrPage(page);
    fetchCareerPaths({ page, pageSize, orderBy: sortBy, title: searchTerm });
  };

  const menu = (
    <Menu onClick={handleSortChange}>
      <Menu.Item key="id">Id</Menu.Item>
      <Menu.Item key="title">Title</Menu.Item>
      <Menu.Item key="created_at">Date Added</Menu.Item>
    </Menu>
  );

  return (
    <div className="manage-career-path-container">
      <PageHeader title="Manage Career Paths">
        <Row>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                gap: 10,
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Search
                placeholder="Search By Name"
                style={{ width: 400 }}
                value={searchText}
                onChange={handleChangeInSearch}
                onSearch={handleSearch}
              />
              <Button
                onClick={clearAllFilters}
                disabled={!filtersEnabled || isLoading.careerPathsData}
              >
                <Icon type="sync" />
                Clear Filters
              </Button>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button disabled={isLoading.careerPathsData}>
                  Sort By
                  <Icon type="sort-ascending" />
                </Button>
              </Dropdown>
              <Button
                disabled={isLoading.careerPathsData}
                key="add"
                onClick={add}
                type="primary"
                icon="plus-circle"
              >
                Add a Career Path
              </Button>
              <Tooltip title="Link PathwayU Career Titles to parent Career Paths from here">
                <Button
                  disabled={isLoading.careerPathsData}
                  onClick={() =>
                    history.push('/manage-career-paths/occupations')
                  }
                  type="primary"
                  icon="select"
                >
                  Manage Career Titles
                </Button>
              </Tooltip>
              <Tooltip title="Manage Subject Areas">
                <Button
                  disabled={isLoading.careerPathsData}
                  onClick={() =>
                    history.push('/manage-career-paths/manage-subject-areas')
                  }
                  type="primary"
                  icon="select"
                >
                  Manage Subject Areas
                </Button>
              </Tooltip>
              <Tooltip title="Manage Programs">
                <Button
                  disabled={isLoading.careerPathsData}
                  onClick={() => history.push('/manage-career-paths/programs')}
                  type="primary"
                  icon="select"
                >
                  Manage Programs
                </Button>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </PageHeader>
      <Table
        loading={isLoading.careerPathsData}
        columns={antdTableColumns}
        dataSource={careerPaths.map(cp => ({ ...cp, key: cp.id }))}
        scroll={{ x: 'auto' }}
        pagination={{
          pageSize: 10,
          total: numPages,
          onChange: (page, pageSize) => handlePagination(page, pageSize),
        }}
      />
      {(editingKey !== -1 || addingKey !== -1) && (
        <EditModal
          isEditing={editingKey !== -1 || addingKey !== -1}
          careerPathWithAssociatedData={careerPathWithAssociatedData}
          auxiliaryDataForCareerPaths={auxiliaryDataForCareerPaths}
          onSave={save}
          onCancel={() => cancel()}
          isLoading={isLoading}
          editingKey={editingKey}
        />
      )}
      {changeLogKey !== -1 && (
        <ChangeLogModal
          isLoading={isLoading}
          careerPathChangeLog={careerPathChangeLog}
          revertCareerPathChange={revertCareerPathChange}
          onClose={closeChangeLogModal}
          careerPathWithAssociatedData={careerPathWithAssociatedData}
          auxiliaryDataForCareerPaths={auxiliaryDataForCareerPaths}
        />
      )}
      {linkedProjectsKey !== -1 && (
        <LinkedProjectsModal
          careerPathId={linkedProjectsKey}
          onClose={() => {
            setLinkedProjectsKey(-1);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  manageCareerPaths: {
    careerPathsMeta,
    careerPaths,
    isLoading,
    careerPathWithAssociatedData,
    auxiliaryDataForCareerPaths,
    careerPathChangeLog,
    searchText,
    filtersEnabled,
    sortBy,
    numPages,
    addingKey,
    changeLogKey,
    editingKey,
  },
}) => ({
  careerPathsMeta,
  careerPaths,
  isLoading,
  careerPathWithAssociatedData,
  auxiliaryDataForCareerPaths,
  careerPathChangeLog,
  searchText,
  filtersEnabled,
  sortBy,
  numPages,
  addingKey,
  changeLogKey,
  editingKey,
});

const mapDispatchToProps = {
  fetchCareerPaths: fetchCareerPathsAction,
  fetchCareerPathsMeta: fetchCareerPathsMetaAction,
  fetchAuxiliaryDataForCareerPaths: fetchAuxiliaryDataForCareerPathsAction,
  fetchCareerPathWithAssociatedData: fetchCareerPathWithAssociatedDataAction,
  addCareerPath: addCareerPathAction,
  updateCareerPath: updateCareerPathAction,
  setCareerPathWithAssociatedData: setCareerPathWithAssociatedDataAction,
  fetchCareerPathChangeLog: fetchCareerPathChangeLogAction,
  revertCareerPathChange: revertCareerPathChangeAction,
  deleteCareerPath: deleteCareerPathAction,
  toggleCareerPathActiveStatus: toggleCareerPathActiveStatusAction,
  searchTextChange: searchTextChangeAction,
  clearFilters: clearFiltersAction,
  setSortBy: setSortByAction,
  setAddingKey: setAddingKeyAction,
  setEditingKey: setEditingKeyAction,
  setChangeLogKey: setChangeLogKeyAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCareerPathsContainer);
