import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Select,
  Spin,
  Table,
  Switch,
} from 'antd';
import { set, get, cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clustersApi from '../../api/cluster';
import Content from '../../component/Content';
import { notify } from '../../utils/notify';
import { fetchAllClusters as fetchAllClustersAction } from '../ClusterManagement/actions';
import {
  useUpdateCluster,
  useGetAllMobileAppClusters,
  useToggleMobileAppFlagOfCluster,
} from './queries';

const { Option } = Select;
const { confirm } = Modal;

const MobileAppClients = ({ clustersLoading, clusters, fetchAllClusters }) => {
  const {
    isSuccess,
    isLoading: isMobileClustersLoading,
    data: response,
    isRefetching,
  } = useGetAllMobileAppClusters();

  const { isLoading: isClusterUpdating, mutate: updateCluster } =
    useUpdateCluster();

  const {
    isLoading: isTogglingMobileCluster,
    mutate: toggleMobileAppFlagOfCluster,
  } = useToggleMobileAppFlagOfCluster();

  const [clusterModalVisible, setClusterModalVisible] = useState(false);
  const [selectedClusterId, setSelectedClusterId] = useState();

  const [schoolNameModalVisible, setSchoolNameModalVisible] = useState(false);
  const [editSchoolName, setEditSchoolName] = useState();

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const renderTableTitle = () => <h3>Clusters</h3>;

  const handleOpenModal = type => {
    if (type === 'cluster') {
      setClusterModalVisible(true);
    } else if (type === 'schoolName') {
      setSchoolNameModalVisible(true);
    }
  };

  const handleCloseModal = type => {
    if (type === 'cluster') {
      setClusterModalVisible(false);
    } else if (type === 'schoolName') {
      setSchoolNameModalVisible(false);
      setEditSchoolName();
    }
    setSelectedClusterId();
  };

  const handleAddCluster = async () => {
    if (!selectedClusterId) return;

    toggleMobileAppFlagOfCluster(
      { clusterId: selectedClusterId, hasMobileApp: true },
      {
        onSuccess: () => {
          fetchAllClusters();
          handleCloseModal('cluster');
        },
        onError: () => {
          notify("Couldn't update cluster settings!", 'error');
        },
      }
    );
  };

  const handleRemoveCluster = async clusterId => {
    toggleMobileAppFlagOfCluster(
      { clusterId, hasMobileApp: false },
      {
        onSuccess: () => {
          fetchAllClusters();
          setEditSchoolName();
        },
        onError: () => {
          notify("Couldn't update cluster settings!", 'error');
        },
      }
    );
  };

  const handleEditSchoolName = async () => {
    try {
      if (!selectedClusterId) return;

      const result = await clustersApi.getOne(selectedClusterId);

      const cluster = set(
        result.data,
        'settings.coreSettings.mobile.schoolName',
        editSchoolName
      );

      updateCluster(
        { clusterId: selectedClusterId, cluster },
        {
          onSettled: () => {
            handleCloseModal('schoolName');
          },
          onError: () => {
            notify("Couldn't update cluster's school name!", 'error');
          },
        }
      );
    } catch (error) {
      notify("Couldn't update cluster's school name!", 'error');
    }
  };

  const handleSearchChange = e => {
    const updatedSearchQuery = e.target.value;
    setSearchQuery(updatedSearchQuery);

    if (updatedSearchQuery === '') {
      setSearchResults(response?.data || []);
    } else {
      const updatedSearchMobileClusters = response?.data?.filter(
        cluster =>
          cluster.id.toString().includes(updatedSearchQuery) ||
          cluster.name.toLowerCase().includes(updatedSearchQuery.toLowerCase())
      );
      setSearchResults(updatedSearchMobileClusters);
    }
  };

  const confirmRemoveCluster = clusterId => () => {
    confirm({
      title: 'Are you sure you want to remove the cluster?',
      onOk() {
        return Promise.resolve(handleRemoveCluster(clusterId));
      },
      onCancel() {},
    });
  };

  const ClusterName = (name, { domain }) => (
    <div>
      <h4 style={{ fontWeight: 'bold' }}>{name}</h4>
      <p style={{ fontSize: '0.8rem' }}>{domain}</p>
    </div>
  );

  const RemoveCluster = clusterId => (
    <Button
      type="link"
      onClick={confirmRemoveCluster(clusterId)}
      style={{ paddingLeft: 0 }}
    >
      Remove
    </Button>
  );

  const handleToggleUseDefaultIcon =
    (clusterId, newUseDefaultIconValue) => async () => {
      try {
        if (!clusterId) return;

        const result = await clustersApi.getOne(clusterId);
        const cluster = cloneDeep(result.data);

        set(
          cluster,
          'settings.coreSettings.mobile.useDefaultAppIconSuperAdmin',
          newUseDefaultIconValue
        );

        updateCluster(
          { clusterId, cluster },
          {
            onError: () => {
              notify(
                "Couldn't update cluster's useDefaultAppIconSuperAdmin value!",
                'error'
              );
            },
          }
        );
      } catch (error) {
        notify(
          "Couldn't update cluster's useDefaultAppIconSuperAdmin value!",
          'error'
        );
      }
    };

  const handleToggleUseAdaptiveIcon =
    (clusterId, newUseAdaptiveIconValue) => async () => {
      try {
        if (!clusterId) return;

        const result = await clustersApi.getOne(clusterId);
        const cluster = cloneDeep(result.data);

        set(
          cluster,
          'settings.coreSettings.mobile.adaptiveIcon',
          newUseAdaptiveIconValue
        );

        updateCluster(
          { clusterId, cluster },
          {
            onError: () => {
              notify(
                "Couldn't update cluster's useDefaultAppIconSuperAdmin value!",
                'error'
              );
            },
          }
        );
      } catch (error) {
        notify(
          "Couldn't update cluster's useDefaultAppIconSuperAdmin value!",
          'error'
        );
      }
    };

  const UseDefaultIcon = (clusterId, school) => {
    const useDefaultIcon = get(
      school,
      'branding.useDefaultAppIconSuperAdmin',
      false
    );
    return (
      <Switch
        id="useDefaultIcon"
        checked={useDefaultIcon}
        onChange={handleToggleUseDefaultIcon(clusterId, !useDefaultIcon)}
      />
    );
  };

  const AdaptiveIcon = (clusterId, school) => {
    const adaptiveIcon = get(school, 'branding.adaptiveIcon', true);
    return (
      <Switch
        id="useAdaptiveIcon"
        checked={adaptiveIcon}
        onChange={handleToggleUseAdaptiveIcon(clusterId, !adaptiveIcon)}
      />
    );
  };

  const ChangeSchoolName = (schoolName, { id }) => (
    <div>
      {Boolean(schoolName) && (
        <Row>
          <Col>
            <p>{schoolName}</p>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Button
            type="link"
            onClick={() => {
              handleOpenModal('schoolName');
              setEditSchoolName(schoolName);
              setSelectedClusterId(id);
            }}
            style={{ padding: 0 }}
          >
            Change
          </Button>
        </Col>
      </Row>
    </div>
  );

  const tableColumns = [
    { title: 'Cluster ID', dataIndex: 'id', key: 'id', width: 100 },
    { title: 'Name', dataIndex: 'name', key: 'name', render: ClusterName },
    {
      title: 'School Name',
      dataIndex: 'schoolName',
      key: 'schoolName',
      render: ChangeSchoolName,
      width: 300,
    },
    {
      title: 'Default Icon',
      dataIndex: 'id',
      key: 'useDefaultIcon',
      render: UseDefaultIcon,
      width: 150,
    },
    {
      title: 'Adaptive Icon',
      dataIndex: 'id',
      key: 'adaptiveIcon',
      render: AdaptiveIcon,
      width: 150,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      width: 250,
      render: RemoveCluster,
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      setSearchResults(response.data);
    }
  }, [isSuccess, response]);

  useEffect(() => {
    fetchAllClusters();
  }, [fetchAllClusters]);

  const getFooterItems = ({ disabled, onCancel, onSubmit }) => [
    <Button key="cancel" onClick={onCancel}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      disabled={disabled}
      onClick={onSubmit}
      loading={isRefetching || isTogglingMobileCluster || isClusterUpdating}
    >
      Save
    </Button>,
  ];

  return (
    <div>
      <PageHeader
        title="Mobile App Clients"
        extra={[
          <Button
            key="add"
            onClick={() => handleOpenModal('cluster')}
            type="primary"
            icon="plus-circle"
          >
            Add a Cluster
          </Button>,
        ]}
      >
        <Divider />
        <Row type="flex">
          <Input.Search
            placeholder="Search By Cluster ID, Name"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ width: '30%' }}
          />
        </Row>
      </PageHeader>
      <Content>
        <Card>
          <Table
            rowKey="id"
            loading={
              isMobileClustersLoading || isTogglingMobileCluster || isRefetching
            }
            columns={tableColumns}
            dataSource={searchResults}
            title={renderTableTitle}
          />
        </Card>
      </Content>
      <Modal
        visible={clusterModalVisible}
        title="Select cluster"
        destroyOnClose
        onCancel={() => handleCloseModal('cluster')}
        footer={getFooterItems({
          disabled: !selectedClusterId || isTogglingMobileCluster,
          onCancel: () => handleCloseModal('cluster'),
          onSubmit: handleAddCluster,
        })}
      >
        <Form>
          <Form.Item name="cluster" label="Select cluster" colon={false}>
            {clustersLoading ? (
              <Spin style={{ width: '100%', textAlign: 'center' }} />
            ) : (
              <Select
                placeholder="Select cluster"
                onChange={setSelectedClusterId}
                value={selectedClusterId}
                showSearch
                filterOption={(input, option) => {
                  const [clusterId, _, clusterName] = option.props.children;
                  return (
                    clusterId.toString().includes(input) ||
                    clusterName.toLowerCase().includes(input.toLowerCase())
                  );
                }}
              >
                {clusters
                  ?.filter(cluster => !cluster.hasMobileApp)
                  .map(cluster => (
                    <Option key={cluster.id} value={cluster.id}>
                      {cluster.id} - {cluster.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={schoolNameModalVisible}
        title="Edit school name"
        destroyOnClose
        onCancel={() => handleCloseModal('schoolName')}
        footer={getFooterItems({
          disabled:
            !editSchoolName ||
            editSchoolName?.trim().length === 0 ||
            isClusterUpdating ||
            isRefetching,
          onCancel: () => handleCloseModal('schoolName'),
          onSubmit: handleEditSchoolName,
        })}
      >
        <Form>
          <Form.Item name="cluster" label="School name" colon={false}>
            <Input
              placeholder="School name"
              value={editSchoolName}
              onChange={e => setEditSchoolName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ cluster: { clustersLoading, clusters } }) => ({
  clustersLoading,
  clusters,
});

const mapDispatchToProps = {
  fetchAllClusters: fetchAllClustersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileAppClients);
