// constants
const prefix = 'ManageCareerPaths/';

export const FETCH_CAREER_PATHS_META = `${prefix}FETCH_CAREER_PATHS_META`;
export const SET_CAREER_PATHS_META = `${prefix}SET_CAREER_PATHS_META`;
export const FETCH_CAREER_PATHS = `${prefix}FETCH_CAREER_PATHS`;
export const SET_CAREER_PATHS = `${prefix}SET_CAREER_PATHS`;
export const FETCH_CAREER_PATH_WITH_ASSOCIATED_DATA = `${prefix}FETCH_CAREER_PATH_WITH_ASSOCIATED_DATA`;
export const SET_CAREER_PATH_WITH_ASSOCIATED_DATA = `${prefix}SET_CAREER_PATH_WITH_ASSOCIATED_DATA`;
export const SET_IS_LOADING = `${prefix}SET_IS_LOADING`;
export const ADD_CAREER_PATH = `${prefix}ADD_CAREER_PATH`;
export const UPDATE_CAREER_PATH = `${prefix}UPDATE_CAREER_PATH`;
export const FETCH_AUXILIARY_DATA_FOR_CAREER_PATHS = `${prefix}FETCH_AUXILIARY_DATA_FOR_CAREER_PATHS`;
export const SET_AUXILIARY_DATA_FOR_CAREER_PATHS = `${prefix}SET_AUXILIARY_DATA_FOR_CAREER_PATHS`;
export const FETCH_CAREER_PATH_CHANGE_LOG = `${prefix}FETCH_CAREER_PATH_CHANGE_LOG`;
export const SET_CAREER_PATH_CHANGE_LOG = `${prefix}SET_CAREER_PATH_CHANGE_LOG`;
export const REVERT_CAREER_PATH_CHANGE = `${prefix}REVERT_CAREER_PATH_CHANGE`;
export const DELETE_CAREER_PATH = `${prefix}DELETE_CAREER_PATH`;
export const TOGGLE_CAREER_PATH_ACTIVE_STATUS = `${prefix}TOGGLE_CAREER_PATH_ACTIVE_STATUS`;
export const CLEAR_FILTERS = `${prefix}CLEAR_FILTERS`;
export const SEARCH_TEXT_CHANGE = `${prefix}SEARCH_TEXT_CHANGE`;
export const SET_FILTERS_ENABLED = `${prefix}SET_FILTERS_ENABLED`;
export const SORT_CHANGE = `${prefix}SORT_CHANGE`;
export const NUM_PAGE_CHANGE = `${prefix}NUM_PAGE_CHANGE`;
export const SET_ADDING_KEY = `${prefix}SET_ADDING_KEY`;
export const SET_EDITING_KEY = `${prefix}SET_EDITING_KEY`;
export const SET_CHANGELOG_KEY = `${prefix}SET_CHANGELOG_KEY`;
export const FETCH_LINKED_PROJECTS = `${prefix}FETCH_LINKED_PROJECTS`;
export const SET_LINKED_PROJECTS = `${prefix}SET_LINKED_PROJECTS`;
export const ADD_LINKED_PROJECTS = `${prefix}ADD_LINKED_PROJECTS`;
export const UPDATE_LINKED_PROJECTS = `${prefix}UPDATE_LINKED_PROJECTS`;
export const DELETE_LINKED_PROJECTS = `${prefix}DELETE_LINKED_PROJECTS`;
export const FETCH_PROJECT_CHANGELOG = `${prefix}FETCH_PROJECT_CHANGELOG`;
export const REVERT_PROJECT = `${prefix}REVERT_PROJECT`;
export const SET_PROJECT_CHANGELOG = `${prefix}SET_PROJECT_CHANGELOG`;
export const FETCH_OCCUPATIONS = `${prefix}FETCH_OCCUPATIONS`;
export const SET_OCCUPATIONS = `${prefix}SET_OCCUPATIONS`;
export const UPDATE_OCCUPATIONS = `${prefix}UPDATE_OCCUPATIONS`;
export const FETCH_SUBJECT_AREAS = `${prefix}FETCH_SUBJECT_AREAS`;
export const SET_SUBJECT_AREAS = `${prefix}SET_SUBJECT_AREAS`;
export const UPDATE_SUBJECT_AREAS = `${prefix}UPDATE_SUBJECT_AREAS`;
export const DELETE_SUBJECT_AREA = `${prefix}DELETE_SUBJECT_AREA`;
export const CREATE_SUBJECT_AREA = `${prefix}CREATE_SUBJECT_AREA`;
export const FETCH_PROGRAMS = `${prefix}FETCH_PROGRAMS`;
export const SET_PROGRAMS_DATA = `${prefix}SET_PROGRAMS_DATA`;
export const FETCH_PROGRAM_COMPANIES = `${prefix}FETCH_PROGRAM_COMPANIES`;
export const SET_PROGRAM_COMPANIES = `${prefix}SET_PROGRAM_COMPANIES`;
export const UPDATE_PROGRAM = `${prefix}UPDATE_PROGRAM`;

// action creators

export const fetchAuxiliaryDataForCareerPaths = () => ({
  type: FETCH_AUXILIARY_DATA_FOR_CAREER_PATHS,
});

export const setAuxiliaryDataForCareerPaths = data => ({
  type: SET_AUXILIARY_DATA_FOR_CAREER_PATHS,
  data,
});

export const fetchCareerPathsMeta = () => ({
  type: FETCH_CAREER_PATHS_META,
});

export const setCareerPathsMeta = data => ({
  type: SET_CAREER_PATHS_META,
  data,
});

export const fetchCareerPaths = data => ({
  type: FETCH_CAREER_PATHS,
  data,
});

export const setCareerPaths = data => ({
  type: SET_CAREER_PATHS,
  data,
});

export const fetchCareerPathWithAssociatedData = data => ({
  type: FETCH_CAREER_PATH_WITH_ASSOCIATED_DATA,
  data,
});

export const setCareerPathWithAssociatedData = data => ({
  type: SET_CAREER_PATH_WITH_ASSOCIATED_DATA,
  data,
});

export const setIsLoading = data => ({
  type: SET_IS_LOADING,
  data,
});

export const addCareerPath = data => ({
  type: ADD_CAREER_PATH,
  data,
});

export const updateCareerPath = data => ({
  type: UPDATE_CAREER_PATH,
  data,
});

export const fetchCareerPathChangeLog = data => ({
  type: FETCH_CAREER_PATH_CHANGE_LOG,
  data,
});

export const setCareerPathChangeLog = data => ({
  type: SET_CAREER_PATH_CHANGE_LOG,
  data,
});

export const revertCareerPathChange = data => ({
  type: REVERT_CAREER_PATH_CHANGE,
  data,
});

export const deleteCareerPath = data => ({
  type: DELETE_CAREER_PATH,
  data,
});

export const toggleCareerPathActiveStatus = data => ({
  type: TOGGLE_CAREER_PATH_ACTIVE_STATUS,
  data,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

export const searchTextChange = data => ({
  type: SEARCH_TEXT_CHANGE,
  data,
});

export const setFiltersEnabled = data => ({
  type: SET_FILTERS_ENABLED,
  data,
});

export const setSortBy = data => ({
  type: SORT_CHANGE,
  data,
});

export const setNumPages = data => ({
  type: NUM_PAGE_CHANGE,
  data,
});

export const setAddingKey = data => ({
  type: SET_ADDING_KEY,
  data,
});

export const setEditingKey = data => ({
  type: SET_EDITING_KEY,
  data,
});

export const setChangeLogKey = data => ({
  type: SET_CHANGELOG_KEY,
  data,
});

export const fetchLinkedProjects = data => ({
  type: FETCH_LINKED_PROJECTS,
  data,
});

export const setLinkedProjects = data => ({
  type: SET_LINKED_PROJECTS,
  data,
});

export const addLinkedProjects = data => ({
  type: ADD_LINKED_PROJECTS,
  data,
});

export const updateLinkedProjects = data => ({
  type: UPDATE_LINKED_PROJECTS,
  data,
});

export const deleteLinkedProjects = data => ({
  type: DELETE_LINKED_PROJECTS,
  data,
});

export const setProjectTemplateChangelog = data => ({
  type: SET_PROJECT_CHANGELOG,
  data,
});

export const fetchProjectChangelog = data => ({
  type: FETCH_PROJECT_CHANGELOG,
  data,
});

export const revertProjectTemplate = data => ({
  type: REVERT_PROJECT,
  data,
});

// selectors
export const careerPathsSelector = ({ manageCareerPaths }) =>
  manageCareerPaths.careerPaths;

export const careerPathsMetaSelector = ({ manageCareerPaths }) =>
  manageCareerPaths.careerPathsMeta;

export const auxiliaryDataForCareerPathsSelector = ({ manageCareerPaths }) =>
  manageCareerPaths.auxiliaryDataForCareerPaths;

export const fetchOccupationsAction = data => ({
  type: FETCH_OCCUPATIONS,
  data,
});

export const setOccupations = data => ({
  type: SET_OCCUPATIONS,
  data,
});

export const updateOccupationsAction = data => ({
  type: UPDATE_OCCUPATIONS,
  data,
});

export const occupationsSelector = ({
  manageCareerPaths: { occupations, totalOccupations },
}) => ({
  occupations,
  total: totalOccupations,
});

export const fetchSubjectAreas = data => ({
  type: FETCH_SUBJECT_AREAS,
  data,
});

export const setSubjectAreas = data => ({
  type: SET_SUBJECT_AREAS,
  data,
});

export const updateSubjectAreas = data => ({
  type: UPDATE_SUBJECT_AREAS,
  data,
});

export const deleteSubjectArea = data => ({
  type: DELETE_SUBJECT_AREA,
  data,
});

export const createSubjectArea = data => ({
  type: CREATE_SUBJECT_AREA,
  data,
});

export const subjectAreasSelector = ({
  manageCareerPaths: { subjectAreas },
}) => ({
  subjectAreas,
});

export const fetchPrograms = data => ({
  type: FETCH_PROGRAMS,
  data,
});

export const setProgramsData = data => ({
  type: SET_PROGRAMS_DATA,
  data,
});

export const programsSelector = ({ manageCareerPaths: { programsData } }) => ({
  programsData,
});

export const fetchProgramCompanies = data => ({
  type: FETCH_PROGRAM_COMPANIES,
  data,
});

export const setProgramCompanies = data => ({
  type: SET_PROGRAM_COMPANIES,
  data,
});

export const updateProgram = data => ({
  type: UPDATE_PROGRAM,
  data,
});
