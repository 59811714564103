import {
  SET_LOAD_TESTS,
  SET_LOAD_TESTS_LOADING,
  SET_LOAD_TEST_HISTORY,
  SET_LOAD_TEST_HISTORY_LOADING,
} from '../actions';

const initialState = {
  loadTests: [],
  loadTestsLoading: true,
  loadTestHistory: [],
  loadTestHistoryLoading: true,
};

const loadTestingReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_LOAD_TESTS:
      return {
        ...state,
        loadTests: data,
      };
    case SET_LOAD_TESTS_LOADING:
      return {
        ...state,
        loadTestsLoading: data,
      };
    case SET_LOAD_TEST_HISTORY:
      return {
        ...state,
        loadTestHistory: data,
      };
    case SET_LOAD_TEST_HISTORY_LOADING:
      return {
        ...state,
        loadTestHistoryLoading: data,
      };
    default:
      return state;
  }
};

export default loadTestingReducer;
