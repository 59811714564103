import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loader from './component/Loader/Loader';
import PrivateRoute from './containers/PrivateRoute/PrivateRoute';
import PublicRoute from './containers/PublicRoute/PublicRoute';
import Dashboard from './containers/Dashboard';
import NotFound from './containers/ErrorPage/NotFound';
import Forbidden from './containers/ErrorPage/Forbidden';
import Auth from './containers/Auth/Auth';
import AuthCallback from './containers/Auth/AuthCallback';
import Layout from './containers/Layout/Layout';
import UserDetail from './containers/UserDetail/UserDetail';
import DatabaseImages from './containers/DatabaseImages';
import ABTesting from './containers/ABTesting/index';
import AdminTags from './containers/AdminTags';
import ClusterManagementRoutes from './containers/ClusterManagement/routes';
import HubManagementRoutes from './containers/HubManagement/routes';
import FeatureManagementRoutes from './containers/FeatureManagement/routes';
import ConfigureAlertBanner from './containers/ConfigureAlertBanner';
import EsLogstash from './containers/EsLogstash';
import SchoolManagement from './containers/SchoolManagement';
import Feedback from './containers/Feedback';
import ManageCache from './containers/ManageCache';
import ManageCareerPaths from './containers/ManageCareerPaths';
import FileUpload from './containers/FileUpload';
import AutomatedMarketing from './containers/AutomatedMarketing';
import BuildAnalytics from './containers/BuildAnalytics';
import AnalyticsReport from './containers/AnalyticsReport';
import AssociateChart from './containers/AssociateChart';
import RefreshES from './containers/RefreshES';
import UpsellPages from './containers/UpsellPages';
import ConfigureProductReleasePosts from './containers/ConfigureProductReleasePosts';
import ManageBulkEmail from './containers/ManageBulkEmail';
import ManageImport from './containers/ManageImport';
import PathwaysProvisioning from './containers/PathwaysProvisioning/routes';
import CampaignsPage from './containers/AutomatedMarketing/CampaignsPage';
import InvitedUniversityContainer from './containers/BridgesAlliance/InvitedUniversityContainer';
import GroupProgramTierRoutes from './containers/GroupProgramTier/routes';
import AccessManagement from './containers/AccessManagement';
import SessionManagement from './containers/SessionManagement';
import UsertypeSettingRoutes from './containers/UserTypeSettings/routes';
import AnalyticsProfileContainer from './containers/AnalyticsProfile';
import IconSet from './containers/IconSet/IconSet';
import StandardHubManagementRoutes from './containers/StandardHubManagement/routes';
import ApproveDeletedHubs from './containers/ApproveDeletedHubs';
import ProcessAlertService from './containers/ProcessAlertService';
import CompanyIndustry from './containers/CompanyIndustry';
import TemplatesAndTips from './containers/TemplatesAndTips/routes';
import EventDrivenAutoNotification from './containers/EventDrivenAutoNotification';
import BenchmarkingGroups from './containers/BenchmarkingGroups';
import MLServiceRoutes from './containers/MLService/routes';
import Compass from './containers/Compass/routes';
import AskAnythingMetrics from './containers/AskAnythingMetrics/AskAnythingMetrics';
import MobileAppClients from './containers/MobileAppClients';
import DefaultTexts from './containers/DefaultTexts';
import LoadTestingRoutes from './containers/LoadTesting/routes';
import SocSync from './containers/SocSync';

const CMSTool = React.lazy(() => import('./containers/CMSTool/routes'));

const PrivateRoutes = () => (
  <Layout>
    <Switch>
      <PrivateRoute
        exact
        path="/"
        accessGroups={['home']}
        component={Dashboard}
      />
      <PrivateRoute
        path="/clusters"
        accessGroups={['cluster-management']}
        component={ClusterManagementRoutes}
      />
      <PrivateRoute
        path="/hubs"
        accessGroups={['hub-management']}
        component={HubManagementRoutes}
      />
      <Route path="/features" component={FeatureManagementRoutes} />
      <PrivateRoute
        exact
        path="/schools"
        accessGroups={['schools']}
        component={SchoolManagement}
      />
      <PrivateRoute
        exact
        path="/feedback"
        accessGroups={['feedback']}
        component={Feedback}
      />
      <PrivateRoute
        exact
        path="/user-detail"
        accessGroups={['user-detail']}
        component={UserDetail}
      />
      <Route exact path="/seed-databases" component={DatabaseImages} />
      <PrivateRoute
        exact
        path="/ab-testing"
        accessGroups={['ab-testing']}
        component={ABTesting}
      />
      <PrivateRoute
        exact
        path="/admin-tags"
        accessGroups={['admin-tags']}
        component={AdminTags}
      />
      <PrivateRoute
        exact
        path="/access-management"
        accessGroups={['access-management']}
        component={AccessManagement}
      />
      <PrivateRoute
        exact
        path="/configure-alert-banner"
        accessGroups={['dashboard-banner']}
        component={ConfigureAlertBanner}
      />
      <PrivateRoute
        exact
        path="/configure-alert-banner/new"
        accessGroups={['dashboard-banner']}
        component={ConfigureAlertBanner}
      />
      <PrivateRoute
        exact
        path="/configure-alert-banner/edit/:id"
        accessGroups={['dashboard-banner']}
        component={ConfigureAlertBanner}
      />
      <PrivateRoute
        exact
        path="/product-releases"
        accessGroups={['product-release']}
        component={ConfigureProductReleasePosts}
      />
      <PrivateRoute
        exact
        path="/product-releases/new"
        accessGroups={['product-release']}
        component={ConfigureProductReleasePosts}
      />
      <PrivateRoute
        exact
        path="/product-releases/edit/:id"
        accessGroups={['product-release']}
        component={ConfigureProductReleasePosts}
      />
      <PrivateRoute
        exact
        path="/manage-cache"
        accessGroups={['manage-cache']}
        component={ManageCache}
      />
      <Route exact path="/file-upload" component={FileUpload} />
      <Route
        path="/manage-career-paths"
        accessGroups={['manage-career-paths']}
        component={ManageCareerPaths}
      />
      <PrivateRoute
        exact
        path="/icon-set"
        accessGroups={['icon-set']}
        component={IconSet}
      />
      <Route path="/automated-marketing" component={AutomatedMarketing} />
      <Route exact path="/campaigns" component={CampaignsPage} />
      <Route path="/campaigns/:id" component={CampaignsPage} />

      <Route exact path="/es-logstash" component={EsLogstash} />
      <Route exact path="/build-analytics-chart" component={BuildAnalytics} />
      <Route
        exact
        path="/build-analytics-chart/new"
        component={BuildAnalytics}
      />
      <Route
        exact
        path="/build-analytics-chart/edit/:id"
        component={BuildAnalytics}
      />
      <Route path="/analytics-reports" component={AnalyticsReport} />
      <Route exact path="/associate-charts" component={AssociateChart} />
      <Route exact path="/associate-charts/new" component={AssociateChart} />
      <Route
        exact
        path="/associate-charts/edit/:id"
        component={AssociateChart}
      />
      <Route exact path="/refresh-es" component={RefreshES} />
      <Route exact path="/upsell-pages" component={UpsellPages} />
      <Route exact path="/manage-bulk-emails" component={ManageBulkEmail} />
      <Route exact path="/manage-imports" component={ManageImport} />
      <Route path="/pathway-provisioning" component={PathwaysProvisioning} />
      <Route path="/soc-sync" component={SocSync} />
      <Route path="/groups-programs-tier" component={GroupProgramTierRoutes} />
      <PrivateRoute
        exact
        path="/cms-tool"
        accessGroups={['cms-content']}
        component={() => (
          <Suspense fallback={<Loader />}>
            <CMSTool />
          </Suspense>
        )}
      />
      <PrivateRoute
        exact
        path="/file-upload"
        accessGroups={['file-upload']}
        component={FileUpload}
      />
      <PrivateRoute
        path="/automated-marketing"
        accessGroups={['automated-marketing']}
        component={AutomatedMarketing}
      />
      <PrivateRoute
        exact
        path="/es-logstash"
        accessGroups={['es-log-stash']}
        component={EsLogstash}
      />
      <PrivateRoute
        exact
        path="/event-driven-auto-notifications"
        accessGroups={['event-driven-auto-notifications']}
        component={EventDrivenAutoNotification}
      />
      <PrivateRoute
        exact
        path="/campaigns"
        accessGroups={['campaigns']}
        component={CampaignsPage}
      />
      <PrivateRoute
        path="/campaigns/:id"
        accessGroups={['campaigns']}
        component={CampaignsPage}
      />
      <PrivateRoute
        path="/ask-anything-metrics"
        component={AskAnythingMetrics}
        accessGroups={['manage-ask-anything']}
      />
      <PrivateRoute
        exact
        path="/build-analytics-chart"
        accessGroups={['analytics-reports', 'analytics-reports-readonly']}
        component={BuildAnalytics}
      />
      <PrivateRoute
        exact
        path="/build-analytics-chart/new"
        accessGroups={['analytics-reports', 'analytics-reports-readonly']}
        component={BuildAnalytics}
      />
      <PrivateRoute
        exact
        path="/build-analytics-chart/edit/:id"
        accessGroups={['analytics-reports', 'analytics-reports-readonly']}
        component={BuildAnalytics}
      />
      <PrivateRoute
        path="/analytics-reports"
        accessGroups={['analytics-reports', 'analytics-reports-readonly']}
        component={AnalyticsReport}
      />
      <PrivateRoute
        exact
        path="/associate-charts"
        accessGroups={['analytics-reports', 'analytics-reports-readonly']}
        component={AssociateChart}
      />
      <PrivateRoute
        exact
        path="/associate-charts/new"
        accessGroups={['analytics-reports', 'analytics-reports-readonly']}
        component={AssociateChart}
      />
      <PrivateRoute
        exact
        path="/associate-charts/edit/:id"
        accessGroups={['analytics-reports', 'analytics-reports-readonly']}
        component={AssociateChart}
      />
      <PrivateRoute
        exact
        path="/refresh-es"
        accessGroups={['refresh-es-documents']}
        component={RefreshES}
      />
      <PrivateRoute
        exact
        path="/upsell-pages"
        accessGroups={['upsell-landing-pages']}
        component={UpsellPages}
      />
      <PrivateRoute
        exact
        path="/manage-bulk-emails"
        accessGroups={['manage-bulk-emails']}
        component={ManageBulkEmail}
      />
      <PrivateRoute
        exact
        path="/manage-imports"
        accessGroups={['manage-imports']}
        component={ManageImport}
      />
      <PrivateRoute
        path="/load-testing"
        accessGroups={['load-testing']}
        component={LoadTestingRoutes}
      />
      <PrivateRoute
        path="/pathway-provisioning"
        accessGroups={['pathway-provisioning']}
        component={PathwaysProvisioning}
      />
      <PrivateRoute
        path="/groups-programs-tier"
        accessGroups={['groups-and-program-tiers']}
        component={GroupProgramTierRoutes}
      />
      <PrivateRoute
        exact
        path="/sessions"
        accessGroups={['sessions']}
        component={SessionManagement}
      />
      <PrivateRoute
        exact
        path="/cms-tool"
        accessGroups={['cms-content']}
        component={() => (
          <Suspense fallback={<Loader />}>
            <CMSTool />
          </Suspense>
        )}
      />
      <PrivateRoute
        path="/compass"
        accessGroups={['compass']}
        component={Compass}
      />
      <PrivateRoute
        path="/templates-and-tips"
        accessGroups={['templates-and-tips']}
        component={TemplatesAndTips}
      />
      <PrivateRoute
        exact
        path="/bridges-alliance/invited-university"
        accessGroups={['bridges-alliance']}
        component={InvitedUniversityContainer}
      />
      <PrivateRoute
        path="/usertype-settings"
        accessGroups={['usertype-settings']}
        component={UsertypeSettingRoutes}
      />
      <PrivateRoute
        path="/standard-hub-management"
        accessGroups={['v2-hub-management']}
        component={StandardHubManagementRoutes}
      />
      <PrivateRoute
        path="/analytics-profile"
        accessGroups={['analytics-profile']}
        component={AnalyticsProfileContainer}
      />
      <PrivateRoute
        path="/approve-deleted-hubs"
        accessGroups={['delete-hub-access']}
        component={ApproveDeletedHubs}
      />
      <Route
        path="/process-alert-service"
        accessGroups={['process-alert-service']}
        component={ProcessAlertService}
      />
      <PrivateRoute
        path="/company-industry"
        accessGroups={['company-industry']}
        component={CompanyIndustry}
      />
      <PrivateRoute
        path="/manage-benchmarking-groups"
        accessGroups={['manage-benchmarking-groups']}
        component={BenchmarkingGroups}
      />
      <PrivateRoute
        path="/default-text"
        accessGroups={['cluster-hub-text']}
        component={DefaultTexts}
      />
      <PrivateRoute
        path="/ml"
        accessGroups={['ml-service']}
        component={MLServiceRoutes}
      />
      <PrivateRoute
        path="/mobile-app-clients"
        accessGroups={['mobile-app-clients']}
        component={MobileAppClients}
      />
      <Route path="/*" component={NotFound} />
    </Switch>
  </Layout>
);

const Routes = () => (
  <Switch>
    <PublicRoute exact path="/403" component={Forbidden} />
    <PublicRoute path="/auth" component={Auth} />
    <PublicRoute path="/saml" component={AuthCallback} />
    <PrivateRoute path="/" component={PrivateRoutes} />
    <Route path="/*" component={NotFound} />
  </Switch>
);

export default Routes;
