import { request } from '../utils/request';

export const fetchCareerPathsMetaApi = () =>
  request.get('/superadmin/career-paths-meta');
export const fetchCareerPathsApi = params =>
  request.get('/superadmin/career-paths', { params });
export const fetchCareerPathWithAssociatedDataApi = params =>
  request.get(`/superadmin/career-paths/${params.careerPathId}`);
export const addCareerPathApi = params =>
  request.post(`/superadmin/career-paths`, params);
export const updateCareerPathApi = params =>
  request.put(`/superadmin/career-paths/${params.careerPathId}`, params);
export const toggleCareerPathActiveStatusApi = params =>
  request.put(
    `/superadmin/career-paths/toggle-career-path-active-status/${params.careerPathId}`,
    params
  );
export const mergeCareerPathsApi = params =>
  request.post(`/superadmin/merge-career-paths`, params);
export const fetchAuxiliaryDataForCareerPathApi = () =>
  request.get('/superadmin/auxiliary-data-for-career-paths');
export const fetchCareerPathChangeLogApi = params =>
  request.get(`/superadmin/career-paths/${params.careerPathId}/change-log`);
export const revertCareerPathChangeApi = params =>
  request.put(`/superadmin/career-paths/change-log/${params.changeLogId}`);
export const deleteCareerPathApi = params =>
  request.delete(`/superadmin/career-paths/${params.careerPathId}`);
export const getLinkedProjectsAPI = ({ careerPathId, ...params }) =>
  request.get(`/superadmin/career-path-project-templates/${careerPathId}`, {
    params,
  });
export const createLinkedProjectsAPI = params =>
  request.post(`/superadmin/career-path-project-templates`, params);
export const updatedLinkedProjectsAPI = ({ projectTemplateId, ...params }) =>
  request.put(
    `/superadmin/career-path-project-templates/${projectTemplateId}`,
    params
  );
export const deleteLinkedProjectsAPI = params =>
  request.delete(
    `/superadmin/career-path-project-templates/${params.id}`,
    params
  );
export const fetchProjectTemplateChangeLogAPI = params =>
  request.get(
    `/superadmin/career-path-project-templates/${params.projectTemplateId}/change-log`
  );
export const revertProjectTemplateAPI = params =>
  request.put(
    `/superadmin/career-path-project-templates/revert/${params.changeLogId}`
  );

export const fetchOccupationsAPI = params =>
  request.get('/superadmin/occupation', { params });

export const updateOccupationsApi = params =>
  request.put(`/superadmin/occupation/${params.occupationId}`, params.payload);

export const fetchSubjectAreasAPI = params =>
  request.get('/superadmin/subject-areas', { params });

export const updateSubjectAreasAPI = params => {
  const { id, description, name } = params;
  return request.put(`/superadmin/subject-areas/${id}`, {
    description,
    name,
  });
};

export const deleteSubjectAreaAPI = id =>
  request.delete(`/superadmin/subject-areas/${id}`);

export const createSubjectAreaAPI = params =>
  request.post('/superadmin/subject-areas', params);

export const fetchProgramsAPI = params =>
  request.get('/superadmin/programs', { params });

export const fetchProgramCompaniesAPI = params =>
  request.get('/superadmin/program-companies', { params });

export const updateProgramAPI = params =>
  request.put('/superadmin/programs', params);
