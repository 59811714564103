import { takeLatest, put, call, select, delay } from 'redux-saga/effects';

import { FETCH_ALL_HUBS, SET_SOC_SYNC_DATA, RUN_SOC_SYNC_DATA, RUN_SOC_FAILED_SYNC_DATA } from './actions';
import * as api from '../../api';
import notify from '../../utils/notify';

export default [
  takeLatest(FETCH_ALL_HUBS, fetchAllHubs),
  takeLatest(RUN_SOC_SYNC_DATA, runSyncSoc),
  takeLatest(RUN_SOC_FAILED_SYNC_DATA, runFailedLogsSync),
];

function* fetchAllHubs() {
  yield put({
    type: SET_SOC_SYNC_DATA,
    data: { hubsLoading: true }
  })
  let allAvailableHubs = yield call(api.hub.getAll, {
    fields: ['id', 'name', 'identifier'],
    fetchElOnlyHubs: true,
  });
  allAvailableHubs = allAvailableHubs.data;
  yield put({
    type: SET_SOC_SYNC_DATA,
    data: {
      allAvailableHubs,
      hubsLoading: false
    }
  })
}

function* runSyncSoc({ data }) {
  try {
    yield put({
      type: SET_SOC_SYNC_DATA,
      data: { runningSyncLoader: true }
    });
    yield call(api.hub.runSocSync, {
      hubId: data?.hubId
    });
    notify('Sync started', 'success');
  } catch (error) {
    notify(error.message, 'error');
  }
  yield put({
    type: SET_SOC_SYNC_DATA,
    data: {
      runningSyncLoader: false
    }
  })
}

function* runFailedLogsSync({ data }) {
  try {
    yield put({
      type: SET_SOC_SYNC_DATA,
      data: { runningFailedSyncLoader: true }
    });
    yield call(api.hub.runSocLogSync, {
      hubId: data?.hubId
    });
    notify('Logs Sync started', 'success');
  } catch (error) {
    notify(error.message, 'error');
  }
  yield put({
    type: SET_SOC_SYNC_DATA,
    data: {
      runningFailedSyncLoader: false
    }
  })
}