import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, PageHeader, Divider } from 'antd';
import { connect } from 'react-redux';
import { fetchLoadTestsAction, updateLoadTestAction } from './actions';
import EditableText from './EditableText';
import style from './index.module.scss';

const LoadTesting = ({
  fetchLoadTests,
  loadTestsLoading,
  loadTests,
  updateLoadTest,
}) => {
  useEffect(() => {
    fetchLoadTests();
  }, [fetchLoadTests]);

  return (
    <PageHeader title="Load Testing" subTitle="View and run load tests">
      <Divider />
      <div className={style['load-testing']}>
        <h3>List of available Load Tests</h3>
        <Table
          loading={loadTestsLoading}
          dataSource={loadTests}
          rowKey="id"
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Name',
              dataIndex: 'slug',
              key: 'slug',
              render: (text, record) => (
                <Link to={`/load-testing/${record.id}`}>{text}</Link>
              ),
            },
            {
              title: 'Description',
              dataIndex: 'description',
              key: 'description',
              render: (text, record) => (
                <EditableText
                  value={text || 'Edit Description'}
                  onChange={newValue => {
                    // console.log(
                    //   `Updating description for ${record.slug} to: ${newValue}`
                    // );
                    updateLoadTest({
                      id: record.id,
                      description: newValue,
                    });
                  }}
                />
              ),
            },
            {
              title: 'Last Ran',
              dataIndex: 'lastRan',
              key: 'lastRan',
            },
          ]}
        />
      </div>
    </PageHeader>
  );
};

const mapStateToProps = ({ loadTestingReducer }) => {
  const { loadTestsLoading, loadTests } = loadTestingReducer;
  return {
    loadTestsLoading,
    loadTests,
  };
};

const mapDispatchToProps = {
  fetchLoadTests: fetchLoadTestsAction,
  updateLoadTest: updateLoadTestAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadTesting);
