import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoadTesting from './LoadTesting';
import LoadTestingView from './LoadTestingView';

const Routes = () => (
  <Switch>
    <Route path="/load-testing/:id" component={LoadTestingView} />
    <Route path="/load-testing" component={LoadTesting} />
  </Switch>
);

export default Routes;
