/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Button,
  PageHeader,
  Row,
  Col,
  Select,
  Modal,
  Input,
  Form,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';
import Search from 'antd/lib/input/Search';
import { connect } from 'react-redux';

import {
  fetchPrograms as fetchProgramsAction,
  fetchProgramCompanies as fetchProgramCompaniesAction,
  updateProgram as updateProgramAction,
} from '../actions';

const { TextArea } = Input;

const ManageProgramsContainer = ({
  programs,
  totalPrograms,
  fetchPrograms,
  fetchProgramCompanies,
  programCompanies,
  isLoading,
  updateProgram,
}) => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: totalPrograms,
  });
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programOccupations, setProgramOccupations] = useState([]);
  useEffect(() => {
    fetchProgramCompanies();
  }, [fetchProgramCompanies]);

  const getProgramColumns = () => [
    {
      title: 'Program Title',
      dataIndex: 'title',
      key: 'title',
      width: 300,
      render: (value, record) => {
        if (record.link) {
          return (
            <a href={record.link} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          );
        }
        return <p className="title">{value}</p>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (value, record) => <p className="description">{value}</p>,
    },
    {
      title: 'Occupations',
      dataIndex: 'occupations',
      key: 'occupations',
      render: (value, record) => (
        <Button
          type="link"
          onClick={() => setProgramOccupations(record.occupations || [])}
        >
          View
        </Button>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="link" onClick={() => setSelectedProgram(record)}>
          Edit
        </Button>
      ),
    },
  ];

  const getPrograms = useCallback(() => {
    fetchPrograms({
      page: pagination.current,
      limit: pagination.pageSize,
      title: searchTerm.trim(),
      companyId: selectedCompany,
    });
  }, [fetchPrograms, pagination, searchTerm, selectedCompany]);

  useEffect(() => {
    const debouncedFunc = debounce(() => {
      getPrograms();
    }, 500);

    debouncedFunc();

    return () => {
      debouncedFunc.cancel();
    };
  }, [searchTerm, pagination, getPrograms]);

  const handleSave = async () => {
    await updateProgram({
      id: selectedProgram.id,
      title: selectedProgram.title,
      description: selectedProgram.description,
    });
    setSelectedProgram(null);
  };

  return (
    <div className="manage-programs-container">
      <PageHeader title="Manage Programs">
        <Row>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 10,
            }}
          >
            <Button
              type="primary"
              icon="caret-left"
              onClick={() => history.goBack()}
            >
              Back to Manage Career Paths
            </Button>
            <Search
              placeholder="Search By Program Title"
              style={{ width: 400 }}
              value={searchTerm}
              onChange={e => {
                setPagination(prev => ({
                  ...prev,
                  current: 1,
                }));
                setSearchTerm(e.target.value);
              }}
              onSearch={getPrograms}
              allowClear
            />
            <Select
              placeholder="Select Company"
              style={{ width: 400 }}
              onChange={value => {
                setSelectedCompany(value);
              }}
              defaultValue={null}
            >
              <Select.Option value={null}>
                <strong>All</strong>
              </Select.Option>
              {programCompanies.map(e => (
                <Select.Option key={e.id} value={e.id}>
                  {`${e.title} (${e.programCount})`}
                </Select.Option>
              ))}
            </Select>
            <div />
          </Col>
        </Row>
      </PageHeader>
      <Table
        loading={isLoading.programs}
        columns={getProgramColumns()}
        dataSource={programs.map(e => ({ ...e, key: e.id }))}
        pagination={{
          total: Number(totalPrograms),
          pageSize: pagination.pageSize,
          current: pagination.current,
          showSizeChanger: true,
          onShowSizeChange: (current, pageSize) =>
            setPagination(prev => ({ ...prev, current, pageSize })),
          onChange: (current, pageSize) =>
            setPagination(prev => ({ ...prev, current, pageSize })),
        }}
      />
      <Modal
        visible={!isEmpty(programOccupations)}
        onCancel={() => setProgramOccupations([])}
        footer={null}
      >
        <h3>Associated Occupations</h3>
        <ul>
          {programOccupations.map((e, i) => (
            <li key={`${e.id}-${i}`}>
              <a
                href={`https://www.mynextmove.org/profile/summary/${e.onetCode}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {e.title}
              </a>
            </li>
          ))}
        </ul>
      </Modal>
      <Modal
        visible={!!selectedProgram}
        onCancel={() => setSelectedProgram(null)}
        footer={
          <div className="modal-footer">
            <Button onClick={() => setSelectedProgram(null)}>Cancel</Button>
            <Button
              type="primary"
              onClick={handleSave}
              loading={isLoading.updateProgram}
            >
              Save
            </Button>
          </div>
        }
        title="Edit Program Details"
      >
        {selectedCompany && (
          <p className="company-title">
            <strong>Company:</strong>{' '}
            {programCompanies.find(e => e.id === selectedCompany)?.title}
          </p>
        )}
        <Form layout="vertical">
          <Form.Item label="Program Title">
            <Input
              value={selectedProgram?.title}
              onChange={e =>
                setSelectedProgram(prev => ({ ...prev, title: e.target.value }))
              }
            />
          </Form.Item>
          <Form.Item label="Program Description">
            <TextArea
              value={selectedProgram?.description}
              onChange={e =>
                setSelectedProgram(prev => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({
  manageCareerPaths: {
    programsData: { programs = [], total = 0 } = {},
    programCompanies = [],
    isLoading = {},
  },
}) => ({
  programs,
  totalPrograms: total,
  isLoading,
  programCompanies,
});

const mapDispatchToProps = {
  fetchPrograms: fetchProgramsAction,
  fetchProgramCompanies: fetchProgramCompaniesAction,
  updateProgram: updateProgramAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProgramsContainer);
